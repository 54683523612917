import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiService from '@/api/new/services/api.service'
import FileSaver from 'file-saver'

const state = {
  tmData: [],
  tmDataPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 50,
    from: 1,
    to: 50
  },
  notInTrainingData: [],
  notInTrainingDataPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 50,
    from: 1,
    to: 50
  },
  tmDataFilter: {
    source_language: null,
    target_language: null
  },
  tmTableFilter: {},
  notInTrainingFilter: {},

  openAiModels: [],
  aiData: [],
  aiDataPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 50,
    from: 1,
    to: 50
  },
  aiDataFilter: {
    source_language: null,
    target_language: null
  },
  aiTableFilter: {},
  addingCustomData: false,

  bulkAiUpdate: false,

  selectedTms: [],
  allTmsSelected: false,

  selectedNotInTraining: [],
  allNotInTrainingsSelected: false,

  selectedAis: [],
  allAisSelected: false
}

const mutations = {
  setTmData(state, segments) {
    state.tmData = segments
  },
  setTmPages(state, tmDataPages) {
    state.tmDataPages = tmDataPages
  },
  setNotInTrainingTmData(state, segments) {
    state.notInTrainingData = segments
  },
  setNotInTrainingDataPages(state, notInTrainingDataPages) {
    state.notInTrainingDataPages = notInTrainingDataPages
  },
  setTmDataFilter(state, filter) {
    state.tmDataFilter.source_language = filter.source
    state.tmDataFilter.target_language = filter.target
  },
  setTmTableFilter(state, { value, type }) {
    if (type) {
      const updatedFilter = { ...state.tmTableFilter }
      if (!value) {
        delete updatedFilter[type]
      } else if (updatedFilter[type] !== value) {
        updatedFilter[type] = value
      }
      state.tmTableFilter = updatedFilter
      state.tmDataPages.current = 1
    }
  },
  resetTmTableFilter(state) {
    state.tmTableFilter = {}
  },
  setNotInTrainingFilter(state, { value, type }) {
    if (type) {
      const updatedFilter = { ...state.notInTrainingFilter }
      if (!value) {
        delete updatedFilter[type]
      } else if (updatedFilter[type] !== value) {
        updatedFilter[type] = value
      }
      state.notInTrainingFilter = updatedFilter
      state.notInTrainingDataPages.current = 1
    }
  },
  resetNotInTrainingFilter(state) {
    state.notInTrainingFilter = {}
  },

  setOpenAiModels(state, models) {
    state.openAiModels = models
  },
  setAiData(state, segments) {
    state.aiData = segments
  },
  setAiPages(state, aiDataPages) {
    state.aiDataPages = aiDataPages
  },
  setAiDataFilter(state, filter) {
    state.aiDataFilter.source_language = filter.source
    state.aiDataFilter.target_language = filter.target
  },
  setAiTableFilter(state, { value, type }) {
    if (type) {
      const updatedFilter = { ...state.aiTableFilter }
      if (!value) {
        delete updatedFilter[type]
      } else if (updatedFilter[type] !== value) {
        updatedFilter[type] = value
      }
      state.aiTableFilter = updatedFilter
      state.aiDataPages.current = 1
    }
  },
  resetAiTablFilter(state) {
    state.aiTableFilter = {}
  },
  setAddingCustomData(state, bool) {
    state.addingCustomData = bool
  },

  setBulkAiUpdate(state, bool) {
    state.bulkAiUpdate = bool
  },

  // Select TM
  selectTm(state, tm) {
    if (!state.selectedTms.includes(tm)) {
      state.selectedTms = [...state.selectedTms, tm]
    }
  },
  deselectTm(state, tm) {
    const index = state.selectedTms.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedTms]
      result.splice(index, 1)
      state.selectedTms = result
    }
    if (state.selectedTms.length === 0) {
      state.allTmsSelected = false
    }
  },

  // Select not in training TM
  selectNotInTraining(state, tm) {
    if (!state.selectedNotInTraining.includes(tm)) {
      state.selectedNotInTraining = [...state.selectedNotInTraining, tm]
    }
  },
  deselectNotInTraining(state, tm) {
    const index = state.selectedNotInTraining.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedNotInTraining]
      result.splice(index, 1)
      state.selectedNotInTraining = result
    }
    if (state.selectedNotInTraining.length === 0) {
      state.allNotInTrainingsSelected = false
    }
  },

  // Select AI
  selectAi(state, ai) {
    if (!state.selectedAis.includes(ai)) {
      state.selectedAis = [...state.selectedAis, ai]
    }
  },
  deselectAi(state, tm) {
    const index = state.selectedAis.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedAis]
      result.splice(index, 1)
      state.selectedAis = result
    }
    if (state.selectedAis.length === 0) {
      state.allAisSelected = false
    }
  },

  setSelectedSegments(state, { type, segments }) {
    state[type] = segments
  },
  setSelectAllSegments(state, { type, bool }) {
    state[type] = bool
  }
}

const actions = {
  // TM Training Data
  async getTmData({ state, commit, dispatch, rootGetters }, payload) {
    const disabled = !payload.training_data
    const page = disabled
      ? (payload?.page ?? state.tmDataPages.current)
      : (payload?.page ?? state.tmDataPages.current)
    const perPage = disabled
      ? state.notInTrainingDataPages.pageSize
      : state.tmDataPages.pageSize

    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          training_data: payload.training_data,
          ...state.tmDataFilter,
          ...(payload.training_data
            ? { ...state.tmTableFilter }
            : { ...state.notInTrainingFilter })
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/filter-training-data?page=${page}&perPage=${perPage}`,
      data
    )
      .then((res) => {
        const segments = res.data.data
        const pages = {
          current: res.data.meta.current_page,
          total: res.data.meta.last_page,
          totalSegments: res.data.meta.total,
          pageSize: res.data.meta.per_page,
          from: res.data.meta.from,
          to: res.data.meta.to
        }
        if (!payload.training_data) {
          commit('setNotInTrainingTmData', segments)
          commit('setNotInTrainingDataPages', pages)
          dispatch('selectAllNotInTraining', { isSelected: false })
        } else {
          commit('setTmData', segments)
          commit('setTmPages', pages)
          dispatch('selectAllTm', { isSelected: false })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTmData({ state, rootGetters }) {
    const requestData = {
      method: 'post',
      url: `/easycat/api/v1/teams/${rootGetters['workspace/currentAccountId']}/translation-memories/export-training-data`,
      data: {
        data: {
          type: 'translation_memory_segments',
          attributes: {
            ...state.tmDataFilter,
            ...state.tmTableFilter,
            ...(state.selectedTms.length > 0
              ? { translation_memory_segment_ids: [...state.selectedTms] }
              : {})
          }
        }
      },
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        const fileName = contentDisposition
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/"/g, '')
        FileSaver.saveAs(res.data, fileName)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addTmData({ state, dispatch, rootGetters }) {
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          translation_memory_segments: state.selectedNotInTraining
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/add-training-data`,
      data
    )
      .then(async () => {
        await dispatch('getTmData', { training_data: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTmData({ dispatch, commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: payload.attributes
      }
    }
    await commit('task/setIsReviewSaved', false, { root: true })
    await commit('task/setSavingSegment', true, { root: true })
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${payload.tmId}/segments/${payload.segmentId}`,
      data
    )
      .then(async () => {
        await dispatch('getTmData', { training_data: payload.training_data })
        await commit('task/setIsReviewSaved', true, { root: true })
        await commit('task/setSavingSegment', false, { root: true })
      })
      .catch((err) => {
        commit('task/setSavingSegment', false, { root: true })
        throw new Error(JSON.stringify(err))
      })
  },
  async bulkUpdateTmDataStatus({ state, dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          translation_memory_segments: state.selectedTms,
          training_status: payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/update-training-status`,
      data
    )
      .then(async () => {
        await dispatch('getTmData', { training_data: true })
        dispatch('selectAllTm', { isSelected: false })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  // AI Training Data
  async getOpenAiModels({ state, commit, rootGetters }) {
    if (state.openAiModels.length > 0) return
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/open-ai-models`
    )
      .then((res) => {
        const models = res.data.data
        commit('setOpenAiModels', models)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getAiData({ state, commit, dispatch, rootGetters }, payload) {
    const page = payload?.page || state.aiDataPages.current
    const perPage = state.aiDataPages.pageSize
    const data = {
      data: {
        type: 'model_training_data',
        attributes: {
          ...state.aiDataFilter,
          ...state.aiTableFilter
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/filter?page=${page}&perPage=${perPage}`,
      data
    )
      .then((res) => {
        const segments = res.data.data
        const pages = {
          current: res.data.meta.current_page,
          total: res.data.meta.last_page,
          totalSegments: res.data.meta.total,
          pageSize: res.data.meta.per_page,
          from: res.data.meta.from,
          to: res.data.meta.to
        }
        commit('setAiData', segments)
        commit('setAiPages', pages)
        dispatch('selectAllAi', { isSelected: false })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportAiData({ state, rootGetters }) {
    const requestData = {
      method: 'post',
      url: `/easycat/api/v1/teams/${rootGetters['workspace/currentAccountId']}/training-data/export`,
      data: {
        data: {
          type: 'model_training_data',
          attributes: {
            ...state.aiDataFilter,
            ...state.aiTableFilter,
            ...(state.selectedAis.length > 0
              ? { training_data_ids: [...state.selectedAis] }
              : {})
          }
        }
      },
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        const fileName = contentDisposition
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/"/g, '')
        FileSaver.saveAs(res.data, fileName)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async generateAiData({ dispatch, state, rootGetters, rootState }, payload) {
    const { globalUse, ...rest } = payload
    const data = {
      data: {
        type: 'model_training_data',
        attributes: {
          ...state.aiDataFilter,
          ...rest,
          ...(!globalUse ? { usage: 'team' } : { usage: 'global' })
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/generate`,
      data
    )
      .then(async () => {
        await dispatch('getAiData', { page: 1 })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createAiData({ dispatch, commit, state, rootGetters }, payload) {
    const data = {
      data: {
        type: 'model_training_data',
        attributes: {
          ...state.aiDataFilter,
          usage: 'team',
          source_type: 'human',
          segments: payload.segments
        }
      }
    }
    await commit('task/setIsReviewSaved', false, { root: true })
    await commit('task/setSavingSegment', true, { root: true })
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data`,
      data
    )
      .then(async () => {
        await dispatch('getAiData')
        commit('setAddingCustomData', false)
        await commit('task/setIsReviewSaved', true, { root: true })
        await commit('task/setSavingSegment', false, { root: true })
      })
      .catch((err) => {
        commit('task/setSavingSegment', false, { root: true })
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAiData({ dispatch, commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'model_training_data',
        attributes: {
          segments: payload.segments
        }
      }
    }
    if (payload.allowSaving) {
      await commit('task/setIsReviewSaved', false, { root: true })
      await commit('task/setSavingSegment', true, { root: true })
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/update`,
      data
    )
      .then(async () => {
        await commit('task/setIsReviewSaved', true, { root: true })
        await commit('task/setSavingSegment', false, { root: true })
        await dispatch('getAiData')
      })
      .catch((err) => {
        if (payload.allowSaving)
          commit('task/setSavingSegment', false, { root: true })
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteAiData({ dispatch, rootGetters }, payload) {
    await ApiEasycatService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/${payload}`
    )
      .then(async () => {
        await dispatch('getAiData')
        dispatch('selectAllTm', { isSelected: false })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async bulkDeleteAiData({ state, dispatch, rootGetters }) {
    const data = {
      data: {
        type: 'model_training_data',
        attributes: {
          training_data_ids: [...state.selectedAis]
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/training-data/delete`,
      data
    )
      .then(async () => {
        await dispatch('getAiData')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  // Select TM
  async selectAllTm({ state, commit }, payload) {
    commit('setSelectAllSegments', {
      type: 'allTmsSelected',
      bool: payload.isSelected
    })
    const tms = state.tmData.map((tm) => tm.id)
    if (payload.isSelected)
      commit('setSelectedSegments', { type: 'selectedTms', segments: tms })
    else commit('setSelectedSegments', { type: 'selectedTms', segments: [] })
  },

  // Select not in training TM
  async selectAllNotInTraining({ state, commit }, payload) {
    commit('setSelectAllSegments', {
      type: 'allNotInTrainingsSelected',
      bool: payload.isSelected
    })
    if (payload.isSelected)
      commit('setSelectedSegments', {
        type: 'selectedNotInTraining',
        segments: state.notInTrainingData.map((tm) => tm.id)
      })
    else
      commit('setSelectedSegments', {
        type: 'selectedNotInTraining',
        segments: []
      })
  },

  // Select AI
  async selectAllAi({ state, commit }, payload) {
    commit('setSelectAllSegments', {
      type: 'allAisSelected',
      bool: payload.isSelected
    })
    const ais = state.aiData.map((ai) => ai.id)
    if (payload.isSelected)
      commit('setSelectedSegments', { type: 'selectedAis', segments: ais })
    else commit('setSelectedSegments', { type: 'selectedAis', segments: [] })
  }
}

export const trainingData = {
  namespaced: true,
  state,
  actions,
  mutations
}
